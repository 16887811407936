@import url(https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
.fade-enter {
    opacity: 0;
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
}

.fade-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

.fade-exit {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

.fade-exit-active {
    opacity: 0;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms, -webkit-transform 500ms;
    transition: opacity 500ms, transform 500ms;
    transition: opacity 500ms, transform 500ms, -webkit-transform 500ms;
}
.scroll__off {
    overflow: hidden;
}

.modal__close {
    display: none !important;
}

.modal__enstry {
    display: block;
}

.modal__out {
    opacity: 1;
    -webkit-animation: output 0.4s 0.4s forwards;
            animation: output 0.4s 0.4s forwards;
    /*transition: opacity 0.4s 1s;*/
}

@-webkit-keyframes output {

    /*from {
        opacity: 1;
    }*/
    to {
        opacity: 0;
    }
}

@keyframes output {

    /*from {
        opacity: 1;
    }*/
    to {
        opacity: 0;
    }
}
.anim__dialog__entry {
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
            transform: translate3d(0px, 0px, 0px) !important;
}

/* @font-face {
  font-family: "Yeseva One";
  src: url("https://fonts.googleapis.com/css2? family = Yeseva + One & display = swap");
} */
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #efefef;
}

/* li {
  list-style-type: none;
} */
a {
  color: black;
  text-decoration: none;
}

.App {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.animation {
  -webkit-transform: translate3d(-280px, 0px, 0px);
          transform: translate3d(-280px, 0px, 0px);
  -webkit-animation: transform 0.7s linear 1s forwards;
  animation: transform 0.7s linear 1s forwards;
}

@-webkit-keyframes transform {
  from {
    -webkit-transform: translate3d(-280px, 0px, 0px);
            transform: translate3d(-280px, 0px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes transform {
  from {
    -webkit-transform: translate3d(-280px, 0px, 0px);
            transform: translate3d(-280px, 0px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

.news__item_h2 {
  font-size: 1.2rem;
  font-Family: "Play";
  font-weight: bold;
  text-align: center;
  margin: 30px 0px 15px 0px;
}

.news__text {
  margin-bottom: 20px;
}

.news__item_visible {
  /* transform: translate3d(280px, 0px, 0px); */
  -webkit-animation: transformItemNews 0.7s linear 1s forwards;
  animation: transformItemNews 0.7s linear 1s forwards;
}

@-webkit-keyframes transformItemNews {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes transformItemNews {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }

  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
