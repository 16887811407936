.scroll__off {
    overflow: hidden;
}

.modal__close {
    display: none !important;
}

.modal__enstry {
    display: block;
}

.modal__out {
    opacity: 1;
    animation: output 0.4s 0.4s forwards;
    /*transition: opacity 0.4s 1s;*/
}

@keyframes output {

    /*from {
        opacity: 1;
    }*/
    to {
        opacity: 0;
    }
}