@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

/* @font-face {
  font-family: "Yeseva One";
  src: url("https://fonts.googleapis.com/css2? family = Yeseva + One & display = swap");
} */
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #efefef;
}

/* li {
  list-style-type: none;
} */
a {
  color: black;
  text-decoration: none;
}

.App {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.animation {
  transform: translate3d(-280px, 0px, 0px);
  -webkit-animation: transform 0.7s linear 1s forwards;
  -moz-animation: transform 0.7s linear 1s forwards;
  -o-animation: transform 0.7s linear 1s forwards;
  animation: transform 0.7s linear 1s forwards;
}

@keyframes transform {
  from {
    transform: translate3d(-280px, 0px, 0px);
  }

  to {
    transform: translate3d(0px, 0px, 0px);
  }
}

.news__item_h2 {
  font-size: 1.2rem;
  font-Family: "Play";
  font-weight: bold;
  text-align: center;
  margin: 30px 0px 15px 0px;
}

.news__text {
  margin-bottom: 20px;
}

.news__item_visible {
  /* transform: translate3d(280px, 0px, 0px); */
  -webkit-animation: transformItemNews 0.7s linear 1s forwards;
  -moz-animation: transformItemNews 0.7s linear 1s forwards;
  -o-animation: transformItemNews 0.7s linear 1s forwards;
  animation: transformItemNews 0.7s linear 1s forwards;
}

@keyframes transformItemNews {
  from {
    transform: translate3d(100%, 0px, 0px);
  }

  to {
    transform: translate3d(0px, 0px, 0px);
  }
}